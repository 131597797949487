enum ToolCategory {
  AUDIO = "audio",
  VISUAL = "visual",
  PRINTING = "printing",
  DIGITAL = "digital",
  OUTDOOR = "outdoor",
  CROSS_MEDIA = "cross-media",
}

export default ToolCategory;
